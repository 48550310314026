
var rootURL = $('meta[name="domain-name"]').attr('content')
    
// Sorting From High to Low, and Low to High
$("#sortView").click(function(){
    $('.rotateSortIcon').toggleClass("down")  ;
})

$("#sortDesc").click(function(){
    $('html, body').animate({ scrollTop: 0 }, 'fast');

    var $this = $(this);

    finalUrl = category+'/desc/'+cl+'/'+category_slug
    
    history.pushState({}, "null", '' + finalUrl + '');

    $.ajax({
        type: 'get',
        url: finalUrl,
        dataType: "json",
        success: function (data) {
            
            $('#sortView').html($this.html()+'<i class="fa fa-chevron-down fa-pull-right pt-1 rotateSortIcon"></i>');
            $('#sortAsc').removeClass('active');
            $('#sortDesc').addClass('active');
            $('#sortPopularity').removeClass('active');

            $('#sortBy').data('value', 'desc');

            $('#productsCard').html(data);

        }
    });
});

    

