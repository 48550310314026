
    $(document).on("click", ".commentReplyThumbUp", function() {
        var $this = $(this);
        var commentReplyThumbUpCount = $this.children('.commentReplyThumbUpCount').data('val');
        var status = $this.children('.fa-thumbs-up').data('val');
        var Cid = $this.children('.Cid').data('val');
        var CRid = $this.children('.CRid').data('val');

        if(status == 1) {
            var commentReplyThumbUpCountsTotal = commentReplyThumbUpCount + 1;

            $this.children('.fa-thumbs-up').data('val', 0);
            $this.children('.fa-thumbs-up').addClass('text-theme');
            $this.children('.commentReplyThumbUpCount').data('val', commentReplyThumbUpCountsTotal);
            $this.children('.commentReplyThumbUpCount').html(commentReplyThumbUpCountsTotal);

        } else {
            var commentReplyThumbUpCountsTotal = commentReplyThumbUpCount - 1;

            $this.children('.fa-thumbs-up').data('val', 1);
            $this.children('.fa-thumbs-up').removeClass('text-theme');
            $this.children('.commentReplyThumbUpCount').data('val', commentReplyThumbUpCountsTotal);
            $this.children('.commentReplyThumbUpCount').html(commentReplyThumbUpCountsTotal);

        }
        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type:'POST',
            data: {
                _token: _CSRFToken,
                page_id: pageID,
                comment_id: Cid,
                reply_id: CRid,
                provider: 'pr',
                status: status,
            },
            url: rootURL+'/comment/reply/like/submit',
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

            }
        });
    });

    $(document).on("click", ".commentThumbUp",  function() {
        var $this = $(this);
        var commentThumbUpCount = $this.siblings('.commentThumbUpCount').data('val');
        var status = $this.data('val');
        var comment_id = $this.siblings('.comment_id').data('val');

        if(status == 1) {
            var commentThumbUpCountsTotal = commentThumbUpCount + 1;

            $this.data('val', 0);
            $this.addClass('text-theme');
            $this.siblings('.commentThumbUpCount').data('val', commentThumbUpCountsTotal);
            $this.siblings('.commentThumbUpCount').html(commentThumbUpCountsTotal);

        } else {
            var commentThumbUpCountsTotal = commentThumbUpCount - 1;

            $this.data('val', 1);
            $this.removeClass('text-theme');
            $this.siblings('.commentThumbUpCount').data('val', commentThumbUpCountsTotal);
            $this.siblings('.commentThumbUpCount').html(commentThumbUpCountsTotal);

        }

        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type:'POST',
            data: {
                _token: _CSRFToken,
                page_id: pageID,
                comment_id: comment_id,
                provider: 'pr',
                status: status,
            },
            url: rootURL+'/comment/like/submit',
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

            }
        });

    });

    
    $(document).on("click", ".editComment", function() {
        $('html, body').animate({ scrollTop: 3000 }, 'fast');

        var Comment = $(this).data('val')
        var comment_id = $(this).siblings('.Cid').data('val')

        $('#CommentFormHolder').html('' +
            '<form id="CommentUpdateForm" action="'+rootURL+'/comment/update" method="post">\n' +
'                 <textarea class="commentTextarea rh-text-area animated fadeInUp" name="comment" rows="3"\n' +
'                     spellcheck="false" required placeholder="aaa a comment...">'+Comment+'</textarea><br>\n' +
'                  <input type="hidden" name="provider" value="pr">\n' +
'                  <input type="hidden" name="comment_id" value="'+comment_id+'">\n' +
'                  <input type="hidden" name="page_id" value="'+pageID+'">\n' +
'                  <div class="row">\n' +
'                      <div class="col-sm-12">\n' +
'                          <span class="cCharsWrapper opacity">--- Characters Remaining</span>\n' +
'                          <button id="UCommentFormBtn" class="rh-cm-btn btn fa-pull-right" value="true">&nbsp;Update Comment </button>\n' +
'                      </div>\n' +
'                  </div>\n' +
'                  <br>\n' +
'            </form>');

    });

    $(document).on('submit','#CommentUpdateForm', function(e){
        e.preventDefault();
        var $this = $(this);
        var reply_id = $(this).siblings('.CRid').data('val');

        $('#UCommentFormBtn').html('Submiting...');

        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {
                $('html, body').animate({ scrollTop: 2490 }, 'fast');

                $('.replyOpen').siblings(".replyFormWrapper");
                $('.cCharsWrapper').html('300 Characters Remaining');

                $('#UCommentFormBtn').html('Update Comment');

                $('#CommentFormHolder').html('' +
                    '<form id="CommentForm" action="'+rootURL+'/comment/submit" method="post">\n' +
                    '                 <textarea class="commentTextarea rh-text-area" name="comment" rows="3"\n' +
                    '                     spellcheck="false" required placeholder="Write a comment..."></textarea><br>\n' +
                    '                  <input type="hidden" name="provider" value="pr">\n' +
                    '                  <input type="hidden" name="page_id" value="'+pageID+'">\n' +
                    '                  <div class="row">\n' +
                    '                      <div class="col-sm-12">\n' +
                    '                          <span class="cCharsWrapper opacity">300 Characters Remaining</span>\n' +
                    '                          <button id="CommentFormBtn" class="rh-cm-btn btn fa-pull-right" value="true">&nbsp;Post Comment </button>\n' +
                    '                      </div>\n' +
                    '                  </div>\n' +
                    '                  <br>\n' +
                    '              </form>');


                $('#Comments').html(data);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-start',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Comment successfully updated!'
                });

            },
            error: function (jqXHR) {
                var data = $.parseJSON(data.responseText);
                if(data.message) {

                }
            }
        });
    });

    $(document).on("click", ".replyEditOpen", function() {
        $('html, body').stop();
        var profileIMG = $(this).siblings('.replyUIMG').data('val')
        var replyContent = $(this).siblings('.replyContent').data('val')
        var CRid = $(this).siblings('.CRid').data('val')

        $(this).parent().parent().parent().parent().siblings('.replyUpdateForm').removeClass('d-none');
        $(this).parent().parent().parent().parent().siblings('.replyUpdateForm').html('' +
            '<div class="row mt-4 p-0 replyContent" data-val="'+replyContent+'">' +
            '<div class="rp-wrapper-left">' +
            '<img class="box-shadow rp-img" +'+profileIMG+'+ width="120" alt="User">' +
            '</div>' +
            '<div class="rp-wrapper-right">' +
            '<div>' +
            '<input value="'+_CSRFToken+'" name="_token" type="hidden" required>' +
            '<input value="'+CRid+'" name="reply_id" type="hidden" required>' +
            '<input value="pr" name="provider" type="hidden" required>' +
            '<div>' +
            '<textarea oninput="auto_grow(this)" class="replyContent textarea rh-text-area-min border-secondary border-2 rounded box-shadow bg-light" ' +
            'name="reply" spellcheck="false" placeholder="Write a reply..." rows="2" autofocus required">'+replyContent+'</textarea>' +
            '<div class="row">\n' +
            '<div class="col-sm-12"><br>\n' +
            '<button class="replyUpdateFormBtn rh-rp-btn btn mt-5 pt-3 fa-pull-right" value="true">&nbsp; Update &nbsp; </button>\n' +
            '</div>\n' +
            '</div>\n' +
            '</div>' +
            '<br>' +
            '</div>' +
            '</div>' +
            '</div>');
    });

    $(document).on('submit','.replyUpdateForm', function(e){
        e.preventDefault();
        var $this = $(this);

        $this.children('.replyUpdateFormBtn').html('Updating..');

        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {

                $this.children('.replyUpdateFormBtn').html('Update');
                $this.siblings().find('.reply-content').html('<p class="animated fadeInUp">'+data+'</p>');
                $this.addClass('d-none');

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-start',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Reply successfully updated!'
                });

            },
            error: function (jqXHR) {
                var data = $.parseJSON(data.responseText);
                if(data.message) {

                }
            }
        });
    });


    $(document).on("click", ".replyOpen", function() {
        $('html, body').stop();
        var profileIMGU = $(this).data('val')

        var Cid = $(this).siblings('.Cid').data('val')
        $(this).siblings('.replyForm').removeClass('d-none');
        $(this).siblings('.replyForm').html('' +
            '<div class="row mt-2 replyCloseHolder">' +
                '<small class="replyClose fa fa-times"></small>' +
                '<div class="rp-wrapper-left">' +
                    '<img class="box-shadow rp-img" '+profileIMGU+' width="120" alt="User">' +
                '</div>' +
                '<div class="rp-wrapper-right">' +
                    '<div>' +
                        '<input value="'+_CSRFToken+'" name="_token" type="hidden" required>' +
                        '<input value="'+pageID+'" name="page_id" type="hidden" required>' +
                        '<input value="'+Cid+'" name="comment_id" type="hidden" required>' +
                        '<input value="pr" name="provider" type="hidden" required>' +
                    '<div>' +
                    '<textarea oninput="auto_grow(this)" maxlength="300" class="textarea rh-text-area-min border-secondary border-2 rounded box-shadow bg-light" ' +
                        'name="reply" spellcheck="false" placeholder="Write a reply..." rows="2" autofocus required">' +
                    '</textarea>' +
                    '<div class="row">\n' +
                        '<div class="col-sm-12"><br>\n' +
                            '<button class="replyFormBtn rh-rp-btn btn mt-5 pt-3 fa-pull-right" value="true">&nbsp; Reply &nbsp; </button>\n' +
                        '</div>\n' +
                    '</div>\n' +
                '</div>' +
                '<br>' +
            '</div>' +
        '</div>' +
    '</div>');
    });

    $(document).on("click", ".replyClose", function() {
       $(this).parent('.replyCloseHolder').addClass('d-none');
    });

    $(document).on('submit', '#CommentForm', function(e){
        e.preventDefault();
        var $this = $(this);

        $('#CommentFormBtn').html('Submiting...');
        var CommentsCount = $('#CommentsCount').data('val');

        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {
                $('html, body').animate({ scrollTop: 2490 }, 'fast');

                var CommentsCountTotal = CommentsCount+1;
                $('#CommentForm')[0].reset();
                $('#CommentFormBtn').html('Post Comment');

                $('#CommentsCount').data('val', CommentsCountTotal);
                $('#CommentsCount').html('Comments ('+CommentsCountTotal+')');

                $('.replyOpen').siblings(".replyFormWrapper");
                $('#cCharsWrapper').html('300 Characters Remaining');


                $('#Comments').html(data);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-start',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Comment successfully submitted!'
                });
            },
            error: function (jqXHR) {
                var data = $.parseJSON(data.responseText);
                if(data.message) {

                }
            }
        });
    });

    
    var maxLength = 300;
    $(document).on('keyup', '#qAndATextarea', function() {
        var textlen = maxLength - $(this).val().length;
        if(textlen == 0){
            $('#qAndACharsWrapper').html('<p class="text-danger animated fadeIn">0 Characters left</p>');

        } else if(textlen == 1){
            $('#qAndACharsWrapper').html(textlen+' Character Remaining');

        } else {
            $('#qAndACharsWrapper').html(textlen+' Characters Remaining');

        }
    });

    $(document).on('keyup', '#commentTextarea', function() {
        var textlen = maxLength - $(this).val().length;
        if(textlen == 0){
            $('#cCharsWrapper').html('<p class="text-danger animated fadeIn">0 Characters left</p>');

        } else if(textlen == 1){
            $('#cCharsWrapper').html(textlen+' Character Remaining');

        } else {
            $('#cCharsWrapper').html(textlen+' Characters Remaining');

        }
    });
