
    // Login Modal 1
    $(document).on('click', ".loginModal1Open", function(e){
        e.preventDefault();
        $("#loginModal1").css('display', 'block');
        $("#loginModal2").css('display', 'none');
        $("#signUpModal1").css('display', 'none');
        $("#signUpModal2").css('display', 'none');
    });

    // Login Modal 2
    $(document).on('click', ".loginModal2Open", function(e){
        e.preventDefault();
        $("#loginModal2").css('display', 'block');
        $("#loginModal1").css('display', 'none');
        $("#signUpModal2").css('display', 'none');
        $("#signUpModal1").css('display', 'none');

        $('#invalid-login').css('display', 'none');
        $('#loginForm')[0].reset();
    });
    $(".cancelLogin").click(function(){
        $("#loginModal1").css('display', 'none');
        $("#loginModal2").css('display', 'none');
        $('.modal-backdrop').remove();

        $('#LoginbtnLoading').css('display', 'none');
        $('#loginForm')[0].reset();
    });

    // Forgot Password
    $("#forgotPasswordModalOpen").click(function(){
        $("#forgotPasswordModal").css('display', 'block');
        $("#loginModal1").css('display', 'none');
    });
    $("#cancelforgotPassword").click(function(){
        $("#forgotPasswordModal").css('display', 'none');
        $("#loginModal1").css('display', 'block');
    });
    $("#cancelforgotPassword2").click(function(){
        $("#forgotPasswordModal2").css('display', 'none');
        $("#forgotPasswordModal1").css('display', 'block');
    });

    // Register Modal 1
    $(".signUpModal1Open").click(function(){
        $("#signUpModal1").css('display', 'block');
        $("#signUpModal2").css('display', 'none');
        $("#loginModal1").css('display', 'none');
        $("#loginModal2").css('display', 'none');
    });

    // Register Modal 2
    $(".signUpModal2Open").click(function(){
        $("#signUpModal2").css('display', 'block');
        $("#signUpModal1").css('display', 'none');
        $("#loginModal1").css('display', 'none');
        $("#loginModal2").css('display', 'none');
    });

    $(".cancelReg").click(function(){
        $("#signUpModal1").css('display', 'none');
        $("#signUpModal2").css('display', 'none');
        $('.modal-backdrop').remove();
    });

    $('#regForm1').on('submit', function(e){
        e.preventDefault();
        var $this = $(this);
        $('#regBtnLoading').css('display', 'inline-block');
        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (response) {
                if(response.success) {
                    location.reload();
                }
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                if(response.message) {
                    $('#LoginbtnLoading').css('display', 'none');
                    $('#invalid-login').css('display', 'block');
                    $('#invalid-login').html(response.message);
                }
            }
        });
    });

    $('#regForm2').on('submit', function(e){
        e.preventDefault();
        var $this = $(this);
        $('#regBtn2Loading').css('display', 'inline-block');
        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (response) {
                if(response.success) {
                    $('#signUpModal2').css('display', 'none');
                    $('#congratsModal').css('display', 'block');
                }
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                if(response.message) {
                    $('#regBtn2Loading').css('display', 'none');
                    $('#invalid-reg2').css('display', 'block');
                    $('#invalid-reg2').html(response.message);
                    $(".captcha span").html(response.captcha);
                }
            }
        });
    });

    $("#closeCongratsModal").click(function(){
        $("#congratsModal").css('display', 'none');
        $('.modal-backdrop').remove();
        location.reload();
    });

    $('#loginForm').on('submit', function(e){
        e.preventDefault();
        var $this = $(this);
        $('#LoginbtnLoading').css('display', 'inline-block');
        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (response) {
                if(response.success) {
                    location.reload();
                }
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                if(response.message) {
                    $('#LoginbtnLoading').css('display', 'none');
                    $('#invalid-login').css('display', 'block');
                    $('#invalid-login').html(response.message);
                }
            }
        });
    });

    $('#adLogNForm').on('submit', function(e){
        e.preventDefault();
        var $this = $(this);
        $('#adLogNFormBtn').html('<i id="LoginbtnLoading" class="fa fa-spinner fa-spin"></i>');

        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (response) {
                if(response.success) {
                    location.reload();
                }
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                if(response.message) {
                    $('#adLogNFormBtn').html('&nbsp;Login');
                    $('#invalid-login').css('display', 'block');
                    $('#invalid-login').html(response.message);
                }
            }
        });
    });

