
    (function () {
        var documentElem = $(document),
            nav = $('#header'), lastScrollTop = 760;
        documentElem.on('scroll', function() {
            var currentScrollTop = $(this).scrollTop();
            if (currentScrollTop > lastScrollTop){
                nav.addClass ('hidden');
            }
            else
                nav.removeClass('hidden');
            lastScrollTop = currentScrollTop;
        })
    })();

    (function () {
        var documentElem = $(document),
            nav = $('#nav-wrapper-main-bottom'), lastScrollTop = 760;
        documentElem.on('scroll', function() {
            var currentScrollTop = $(this).scrollTop();
            if (currentScrollTop > lastScrollTop) nav.addClass('hidden');
            else nav.removeClass('hidden');
            lastScrollTop = currentScrollTop;
        })
    })();

    // (function () {
    //     var documentElem = $(document),
    //         nav = $('#nav-bottom'), lastScrollTop = 190;
    //     documentElem.on('scroll', function() {
    //         var currentScrollTop = $(this).scrollTop();
    //         if (currentScrollTop > lastScrollTop){
    //             nav.addClass('hidden'),
    //                 $("#mover").addClass('hidden'),
    //                 $("#navBottomToggleShell").addClass('show');
    //             $("#navBottomToggleShell").css('display', 'inline-block');
    //             $("#mover").removeClass('true');
    //             $(".logo_pbs").css('opacity', '.02');
    //             $("#nav-main").removeClass('box-shadow');
    //         }
    //         else
    //             nav.removeClass('hidden'),
    //                 $("#navBottomToggleShell").css('display', 'none'),
    //                 $("#mover").removeClass('hidden'),
    //                 $(".logo_pbs").css('opacity', '1'),
    //                 $("#nav-main").addClass('box-shadow'),
    //         lastScrollTop = currentScrollTop;
    //     })
    // })();

    (function () {
        var documentElem = $(document),
            nav = $('#nav-bottom-mb'), lastScrollTop = 190;
        documentElem.on('scroll', function() {
            var currentScrollTop = $(this).scrollTop();
            if (currentScrollTop > lastScrollTop){
                nav.addClass('hidden');
            }
            else
                nav.removeClass('hidden');
            lastScrollTop = currentScrollTop;
        })
    })();

    (function () {
        var documentElem = $(document),
            nav = $('#nav-top-mb'), lastScrollTop = 90;
        documentElem.on('scroll', function() {
            var currentScrollTop = $(this).scrollTop();
            if (currentScrollTop > lastScrollTop){
                nav.css('opacity','0');
            }
            else
                nav.css('opacity','1');
            // lastScrollTop = currentScrollTop;
        })
    })();

    // Nav Bottom Toggle
    $("#navBottomToggle").click(function(){
        $("#nav-bottom").removeClass('hidden');
        $("#navBottomToggleShell").css('display', 'none');
        $(".logo_pbs").css('opacity', '1');
        $("#mover").removeClass('hidden');
    });


    (function () {
        var documentElem = $(document),
            nav = $('#autoFix'), lastScrollTop = 322;
        documentElem.on('scroll', function() {
            var currentScrollTop = $(this).scrollTop();
            if (currentScrollTop > lastScrollTop)
                nav.addClass('affix-div');
            else
            nav.removeClass('affix-div');
        })
    })();

    
    (function () { 
        var documentElem = $(document),
            nav = $('#autoFix2'), lastScrollTop = 92;
        documentElem.on('scroll', function() {
            var currentScrollTop = $(this).scrollTop();
            if (currentScrollTop > lastScrollTop)
                nav.addClass('affix-div2');
            else
            nav.removeClass('affix-div2');
        })
    })();

    // Push Nav
    (function() {
        var pushingNavTrigger = document.getElementsByClassName('js-cd-nav-trigger');

        if(pushingNavTrigger.length > 0) {
            var mainContent = document.getElementsByClassName('main__content')[0],
                navAnimating = false;

            pushingNavTrigger[0].addEventListener('click', function(event) {
                event.preventDefault();
                if(navAnimating) return; // already animating -> do not toggle
                navAnimating = true;
                Util.toggleClass(document.body, 'nav-is-open', !Util.hasClass(document.body, 'nav-is-open'));
            });

            mainContent.addEventListener('transitionend', function(){
                navAnimating = false; // wait for the ened of animation to reset the variable
            });
        }
    }());