
$(document).on('keyup', '#searchBar', function (e) {
        
    var url = $('meta[name="domain-name"]').attr('content')
    var _CSRFToken = $('meta[name="csrf-token"]').attr('content')

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': _CSRFToken
        }
    });

    $('#searchForm').on('submit', function (e) {
        e.preventDefault();
        var searchBar = $('#searchBar').val();

        history.pushState({}, "null", url + '/search/pop/cg/' + searchBar) 
        $("html, body").animate({ scrollTop: 0 }, "fast");

        location.reload()

    })

    var keyword = $(this).val();
    if(keyword != '') {
            $('#search-panel').css('display', 'block');
            $.ajax({
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', _CSRFToken);
                },
                type:'POST',
                data: {
                    _token: _CSRFToken,
                    keyword: keyword,
                },
                url: url+'/search',
                success:function(data){
                    $('#search-result').html(data);
                }
            });
        } else {

            $('#search-panel').css('display', 'none');

            if ($('#search-result').val().length !== 0) {

                $('.searchable').each(function() {

                    //Handle special characters used in regex
                    var searchregexp = new RegExp($("#search").val().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi");

                    //$& will maintain uppercase and lowercase characters.
                    $(this).html($(this).html().replace(searchregexp, "<span class = 'highlight'>$&</span>"));

                });

            }

        }

    });