$(document).ready(function () {

  $('#DynamicContent').html(make_skeleton());
    setTimeout(function(){
        $('#Content').removeClass('d-none')
        $('#DynamicContentMargin').css('margin-top', '0%')
        $('#DynamicContent').html('');
        $('.zoomContainer').css('margin-top', '0%')

    }, 3000);

  function make_skeleton()
  {
    var output = '';
    for(var count = 0; count < 1; count++)
    {
      output += '<div class="ph-item bg-white rh-well p-3">';
        output += '<div class="ph-col-4 p-0">';
            output += '<div class="ph-picture" style="height: 370px;width: 370px"></div>';
        output += '</div>';
        output += '<div class="ph-col-6">';
            output += '<div class="ph-row">';
                output += '<div class="ph-col-12 big"></div>';
                output += '<div class="ph-col-8"></div>';
                output += '<div class="ph-col-6"></div>';
                output += '<br>';
                output += '<br>';
                output += '<br>';
                output += '<br>';
                output += '<hr>';
                output += '<div class="ph-col-8 big"></div>';
                output += '<div class="ph-col-8"></div>';
                output += '<div class="ph-col-6"></div>';
                output += '<br>';
                output += '<br>';
                output += '<div class="ph-col-6"></div>';
                output += '<div class="ph-col-8"></div>';
                output += '<br>';
                output += '<br>';
                output += '<div class="ph-col-6 big" style="height: 40px"></div>';
                output += '<div class="ph-col-6 big" style="height: 40px"></div>';
            output += '</div>';
        output += '</div>';
    output += '</div>';

    output += '<div class="ph-item bg-white rh-well"></div>';
    output += '<div style="margin-top: 40%"></div>';
    }
    return output;
  }

});