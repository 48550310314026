$(document).on("click", ".heart_tog", function(e) {
    e.preventDefault();

    var $this = $(this);
    var status = $this.data('val');
    var provider = $this.siblings('.provider').data('val');
    var object_id = $this.siblings('.object_id').data('val');

    if(status == 1) {

        $this.data('val', 0);
        $this.removeClass('far');
        $this.addClass('fa');

    } else {

        $this.data('val', 1);
        $this.addClass('far');
        $this.removeClass('fa');

    }

    $.ajax({
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', _CSRFToken);
        },
        type:'POST',
        data: {
            _token: _CSRFToken,
            object_id: object_id,
            object_class: provider,
            status: status,
        },
        url: rootURL+'/heart/card/send-request',
        error: function (jqXHR) {
            var response = $.parseJSON(jqXHR.responseText);

        }

    });

});

$(document).on('click', '#heartSend', function(e){
    e.preventDefault();
    var status = $('#heartStatus').data('val');
    var provider = $('#provider').data('val');
    var heartCount = $('#heartCount').data('val');

    if(status == 1) {
        var heartCountUpdate = heartCount + 1;

        $('#heartStatus').data('val', 0);
        $('#heartStatus').removeClass('far');
        $('#heartStatus').addClass('fa');

        $('#heartCount').data('val', heartCountUpdate);
        $('#heartCount').html('Favorite ('+heartCountUpdate+')');

    } else {
        var heartCountUpdate = heartCount - 1;

        $('#heartStatus').data('val', 1);
        $('#heartStatus').addClass('far');
        $('#heartStatus').removeClass('fa');

        $('#heartCount').data('val', heartCountUpdate);
        $('#heartCount').html('Favorite ('+heartCountUpdate+')');
    }

    $.ajax({
        beforeSend: function (xhr) {
            xhr.setRequestHeader('X-CSRF-Token', _CSRFToken);
        },
        type:'POST',
        data: {
            _token: _CSRFToken,
            page_id: pageID,
            provider: provider,
            status: status
        },
        url: rootURL+'/heart/send-request',
        error: function (jqXHR) {
            var response = $.parseJSON(jqXHR.responseText);

        }
    });

});