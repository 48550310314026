
    $(".myAccount").click(function(){
        var finalURL = rootURL+'/user/account/profile';
        history.pushState({}, "null", ''+finalURL+'');
        location.reload();

    });

    $(".myOrders").click(function(){
        var finalURL = rootURL+'/user/orders';
        history.pushState({}, "null", ''+finalURL+'');
        location.reload();

    });

    $(".myNoti").click(function(){
        var finalURL = rootURL+'/user/notifications/updates';
        history.pushState({}, "null", ''+finalURL+'');
        location.reload();

    });

    $(document).on('click', '.ordersLinker', function(){
        $('#domHo').html('<div class="p-5"><i class="fa fa-spinner fa-spin"></i></div>');
        var $this = $(this);
        var path = $this.data('path') 
        var finalURL = rootURL+'/user/'+path;
        history.pushState({}, "null", ''+finalURL+'') 
              
        $.ajax({
            type:'get',
            url: finalURL,
            success:function(data){
                $this.attr('ordersLinker') 
                $this.addClass('active') 
                $this.siblings().removeClass('active') 
                $('#domHo').html(data)

            },  error: function (jqXHR) {
                conError();
            }
        });
    });

    $(document).on('click', '.notiLinker', function(){
        $('#domHo').html('<div class="p-5"><i class="fa fa-spinner fa-spin"></i></div>');
        var $this = $(this);
        var path = $this.data('path') 
        var finalURL = rootURL+'/user/'+path;
        history.pushState({}, "null", ''+finalURL+'') 
              
        $.ajax({
            type:'get',
            url: finalURL,
            success:function(data){
                $this.addClass('active') 
                $this.siblings().removeClass('active') 
                $('#domHo').html(data.html)
                $('#notiActive').html(data.notiActive)

            },  error: function (jqXHR) {
                conError();
            }
        });
    });

    $(document).on('submit','#accountUpdateForm', function(e){
        e.preventDefault()
        var $this = $(this)

        $('#accountUpdateBtn').html('<i class=" submit-loading fa fa-spinner fa-spin"></i>&nbsp;Updating...')
        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {
                $('#accountUpdateBtn').html('Update')

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Account successfully updated!'
                });
                
                setTimeout(function(){ location.reload() } , 1000);  
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText)
                if(response.message) {
                    $('#accountUpdateBtn').html('Update')
                    $('#invalid-user-update').html(response.message)
                }
            }
        });
    });

    $(document).on('submit','#UaddAddressFrom', function(e){
        e.preventDefault();
        var $this = $(this);

        $('#UaddAddressFromBtn').html('<i class=" submit-loading fa fa-spinner fa-spin"></i>&nbsp;Submitting...');
        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {
                location.reload();
            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                if(response.message) {
                    $('#UaddAddressFromBtn').html('Submite');
                    $('#invalid-user-update').removeClass('d-none');
                    $('#invalid-user-update').css('display', 'block');
                    $('#invalid-user-update').html(response.message);
                }
            }
        });
    });

    $("input.userName").on({
        keydown: function(e) {
            if (e.which === 32)
                return false;
        },
        change: function() {
            this.value = this.value.replace(/\s/g, "");
        }
    });

    $("input#inpPhone2").on({
        keydown: function(e) {
            if (e.which === 32)
                return false;
        },
        change: function() {
            this.value = this.value.replace(/\s/g, "");
        }
    });

    $(document).on("click", "#inpEmail", function() {
        $(this).css('display', 'none')
        $('#inpEmail2').removeClass('d-none')
        $('#inpEmail2').css('display', 'block')

    });

    $(document).on("click", "#inpPhone", function() {
        $(this).css('display', 'none')
        $('#inpPhone2').removeClass('d-none')
        $('#inpPhone2').css('display', 'block')

    });

    
    $(document).on('submit','#UAPasswordChange', function(e){
        e.preventDefault();
        var $this = $(this);

        $('#UpdatePasswordBtn').html('<i class="submit-loading fa fa-spinner fa-spin"></i>&nbsp; Updating...');

        $.ajax({
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {
                $('#UpdatePasswordBtn').html('Update');
                $('#invalid-pass-update').html('');

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Password successfully updated!'
                });
                
                $this[0].reset();

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                if(response.message) {
                    $('#UpdatePasswordBtn').html('Update');
                    $('#invalid-pass-update').css('display', 'block');
                    $('#invalid-pass-update').html(response.message);
                }
            }
            
        });
    });