
// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;
// require('bootstrap');
// window.Swal = require('sweetalert2');

$(document).ready(function () {
    // window.Popper = require('popper.js');

    var rootURL = $('#rootURL').data('value');
    var pageID = $('#pageID').data('val')
    var _CSRFToken = $('meta[name="csrf-token"]').attr('content')

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': _CSRFToken
        }
    });
    
    require('./components/animations');
    require('./components/auth');
    require('./components/autoTyping');
    require('./components/cart');
    require('./components/comments');
    require('./components/filter');
    require('./components/functions');
    require('./components/getLocation');
    require('./components/getVariation');
    require('./components/heart');
    require('./components/nav');
    require('./components/search');
    require('./components/user');
    require('./components/view');
    require('./components/skeleton');
    require('./components/finder');

    // User Center
    $('.userLinker').on('click', function(){
        $('#domHo').html('<div class="p-5"><i class="fa fa-spinner fa-spin"></i></div>');
        var $this = $(this);
        var path = $this.data('path') 
        var finalURL = rootURL+'/user/'+path;
        history.pushState({}, "null", ''+finalURL+'') 
              
        $.ajax({
            type:'get',
            url: finalURL,
            success:function(data){
                $this.addClass('active') 
                $this.siblings().removeClass('active') 
                $('#domHo').html(data)

            },  error: function (jqXHR) {
                conError();
            }
        });
    });

    $(document).on("click", "#upload_icon", function() {
        $("input[id='upload_image']").click();
        event.preventDefault();
    });

    // Page Search Results
    $("#pagesSearchInputForm").on('submit', function(e){    
            e.preventDefault();
    
            var searchKeyword = $('#pageSearchBar').val();
            var type = $('#pageType').data('type');
            var finalURL = rootURL+'/pages/'+type+'/'+searchKeyword+'/search';
    
            history.pushState({}, "null", finalURL)
            location.reload()
    
    });

    // Help Center Search Results
      $("#HCSearchInputForm").on('submit', function(e){    
        e.preventDefault()
        var searchKeyword = $('#HCSearchBar').val()
        var finalURL = rootURL+'/help-center/search/'+searchKeyword

        history.pushState({}, "null", finalURL)
        location.reload()

    });
    
    // Media Library Extend
    $(document).on("click", ".mLExtendBtn", function() {
        var $this = $(this);

        $this.html('<span class="fa fa-spinner fa-spin"></span>&nbsp;&nbsp;Loading...');
        var loadStatus = $this.siblings('.mLLoadStatus').data('val');

        console.log(loadStatus);
        $.ajax({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type:'POST',
            data: {
                _token: _CSRFToken,
                load_status: loadStatus,
            },
            url: rootURL+'/media-library/load-more',
            success: function (data) {
                $this.parent('#mLExtend').html(data);

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

            }
        });

    });

    $(document).on("click", ".load-more-mn-btn", function() {
        var $this = $(this);

        $this.html('<span class="fa fa-spinner fa-spin"></span>&nbsp;&nbsp;Loading...');
        var loadStatus = $this.siblings('.mnLoadStatus').data('val');

        $.ajax({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type:'POST',
            data: {
                _token: _CSRFToken,
                load_status: loadStatus,
                provider: 'pr'
            },
            url: rootURL+'/main/load-more',
            success: function (data) {
                $this.parent().parent('#JustFUProducts').html(data);

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
            }
        });
    });

    $(document).on("click", ".load-more-btn", function() {
        var $this = $(this);
        var Cid = $this.data('val');
        var loadStatus = $this.siblings('.loadStatus').data('val');

        $this.html('<span class="fa fa-spinner fa-spin"></span>&nbsp;&nbsp;Loading...');

        $.ajax({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
            },
            type:'POST',
            data: {
                _token: _CSRFToken,
                comment_id: Cid,
                load_status: loadStatus,
                page_id: pageID,
                provider: 'pr'
            },
            url: rootURL+'/comment/reply/load-more',
            success: function (data) {
                var newLoadStatus = loadStatus+2;
                $this.parent().parent('.Replies').html(data);
                $this.siblings('.loadStatus').data('val', newLoadStatus);

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

            }
        });

    });

    $(document).on('click', '.question_links .pagination .page-item a', function(e){
        e.preventDefault();
        $('html, body').stop();

        var page = $(this).attr('href').split('page=')[1];
            fetch_questions(page);
    });


    function fetch_questions(page)
    {
        $.ajax({
            url: rootURL+'/question/pagination/'+pageID+'/fetch?page='+page,
            success:function(data)
            {
                $('#qAndAnswer').html(data);
            }
        });

    }

    $(document).on('click', '.comment_links .pagination .page-item a', function(e){
        e.preventDefault();
        $('html, body').animate({ scrollTop: 2700 }, 'fast');

        var page = $(this).attr('href').split('page=')[1];
            fetch_pr_comments(page);
    });


    $(".linkToReviews").click(function() {
        scrollToAnchor('Reviews');
    });
    $("#linkToQAndAnswer").click(function() {
        scrollToAnchor('QaACount');
    });
    $("#linkToComments").click(function() {
        scrollToAnchor('Comments');
    });

    $('#QuestionForm').on('submit', function(e){
        e.preventDefault();
        var $this = $(this);
        $('#QuestionFormBtn').html('Submiting...');
        
        $.ajax({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            type: $this.attr('method'),
            url: $this.attr('action'),
            data: $this.serializeArray(),
            dataType: $this.data('type'),
            success: function (data) {
                $('#QuestionForm')[0].reset();
                $('#QuestionFormBtn').html('Post Question');
                $('#qAndAnswer').html(data);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-start',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Question successfully submitted!'
                });

            },
            error: function (jqXHR) {
                var data = $.parseJSON(data.responseText);
                if(data.message) {

                }
            }
        });
    });

    // Product Category Sorting
    // Assign your element ID to a variable.
    var progress = document.getElementById("progressBar");
    // Pause the animation for 100 so we can animate from 0 to x%
    setTimeout(
        function(){
            progress.style.width = '100%';
            progress.style.backgroundColor = 'green';
        } ,100);

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $(document).on('click', '.login-eye-toggle', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#passwordLogin");

        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
    });

    $(document).on('click', '.reg1A-eye-toggle', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#passwordReg1A");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
    });

    $(document).on('click', '.reg1B-eye-toggle', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#passwordReg1B");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
    });

    $(document).on('click', '.reg2A-eye-toggle', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#passwordReg2A");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
    });

    $(document).on('click', '.reg2B-eye-toggle', function() {

        $(this).toggleClass("fa-eye fa-eye-slash");

        var input = $("#passwordReg2B");
        input.attr('type') === 'password' ? input.attr('type','text') : input.attr('type','password')
    });

/*
[License](https://codyhouse.co/license)
 */

// Utility function
    function Util () {};

    /*
        class manipulation functions
    */
    Util.hasClass = function(el, className) {
        if (el.classList) return el.classList.contains(className);
        else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    };

    Util.addClass = function(el, className) {
        var classList = className.split(' ');
        if (el.classList) el.classList.add(classList[0]);
        else if (!Util.hasClass(el, classList[0])) el.className += " " + classList[0];
        if (classList.length > 1) Util.addClass(el, classList.slice(1).join(' '));
    };

    Util.removeClass = function(el, className) {
        var classList = className.split(' ');
        if (el.classList) el.classList.remove(classList[0]);
        else if(Util.hasClass(el, classList[0])) {
            var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
            el.className=el.className.replace(reg, ' ');
        }
        if (classList.length > 1) Util.removeClass(el, classList.slice(1).join(' '));
    };

    Util.toggleClass = function(el, className, bool) {
        if(bool) Util.addClass(el, className);
        else Util.removeClass(el, className);
    };

    Util.setAttributes = function(el, attrs) {
        for(var key in attrs) {
            el.setAttribute(key, attrs[key]);
        }
    };

    /*
      DOM manipulation
    */
    Util.getChildrenByClassName = function(el, className) {
        var children = el.children,
            childrenByClass = [];
        for (var i = 0; i < el.children.length; i++) {
            if (Util.hasClass(el.children[i], className)) childrenByClass.push(el.children[i]);
        }
        return childrenByClass;
    };

    /*
        Animate height of an element
    */
    Util.setHeight = function(start, to, element, duration, cb) {
        var change = to - start,
            currentTime = null;

        var animateHeight = function(timestamp){
            if (!currentTime) currentTime = timestamp;
            var progress = timestamp - currentTime;
            var val = parseInt((progress/duration)*change + start);
            element.setAttribute("style", "height:"+val+"px;");
            if(progress < duration) {
                window.requestAnimationFrame(animateHeight);
            } else {
                cb();
            }
        };

        //set the height of the element before starting animation -> fix bug on Safari
        element.setAttribute("style", "height:"+start+"px;");
        window.requestAnimationFrame(animateHeight);
    };

    /*
        Smooth Scroll
    */

    Util.scrollTo = function(final, duration, cb) {
        var start = window.scrollY || document.documentElement.scrollTop,
            currentTime = null;

        var animateScroll = function(timestamp){
            if (!currentTime) currentTime = timestamp;
            var progress = timestamp - currentTime;
            if(progress > duration) progress = duration;
            var val = Math.easeInOutQuad(progress, start, final-start, duration);
            window.scrollTo(0, val);
            if(progress < duration) {
                window.requestAnimationFrame(animateScroll);
            } else {
                cb && cb();
            }
        };

        window.requestAnimationFrame(animateScroll);
    };

    /*
      Focus utility classes
    */

//Move focus to an element
    Util.moveFocus = function (element) {
        if( !element ) element = document.getElementsByTagName("body")[0];
        element.focus();
        if (document.activeElement !== element) {
            element.setAttribute('tabindex','-1');
            element.focus();
        }
    };

    /*
      Misc
    */

    Util.getIndexInArray = function(array, el) {
        return Array.prototype.indexOf.call(array, el);
    };

    Util.cssSupports = function(property, value) {
        if('CSS' in window) {
            return CSS.supports(property, value);
        } else {
            var jsProperty = property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase();});
            return jsProperty in document.body.style;
        }
    };

    /*
        Polyfills
    */
//Closest() method
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function(s) {
            var el = this;
            if (!document.documentElement.contains(el)) return null;
            do {
                if (el.matches(s)) return el;
                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        };
    }

//Custom Event() constructor
    if ( typeof window.CustomEvent !== "function" ) {

        function CustomEvent ( event, params ) {
            params = params || { bubbles: false, cancelable: false, detail: undefined };
            var evt = document.createEvent( 'CustomEvent' );
            evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
            return evt;
        }

        CustomEvent.prototype = window.Event.prototype;

        window.CustomEvent = CustomEvent;
    }

    /*
        Animation curves
    */
    Math.easeInOutQuad = function (t, b, c, d) {
        t /= d/2;
        if (t < 1) return c/2*t*t + b;
        t--;
        return -c/2 * (t*(t-2) - 1) + b;
    };


    // $.validate({
    //     modules : 'location, date, security, file',
    //     onModulesLoaded : function() {
    //         $('#country').suggestCountry();
    //     }
    // });

    // // Restrict presentation length
    // $('#presentation').restrictLength( $('#pres-max-length') );


});

$(document).ready(function () {

    // Capcha
    $(".btn-refresh").click(function(){
        var link = $(this).data("id");
        console.log('clicked');

        $.ajax({
            type:'GET',
            url: link,
            success:function(data){
                $(".captcha span").html(data.captcha);
            }
        });
    });

    var rootURL = $('#rootURL').data('value');
    var csrfToken = $('#csrfToken').data('value');
    var max = $('#productQty').data('q');

    $('.mnp-cart-minus').click(function () {
        var $input = $(this).parent().find('input');
        $('#plusQty').removeClass('cur-not-allowed');
        $('#plusQty').addClass('rh-btn');
        $('#minusQty').addClass('pointer');

        if($input.val() < 3 ){
            $('#minusQty').removeClass('rh-btn');
            $('#minusQty').addClass('cur-not-allowed');
        }

        var count = parseInt($input.val()) - 1;
        count = count < 1 ? 1 : count;
        $input.val(count);
        $input.change();
        return false;

    });

    $('#productQty').keyup(function(){
        var max = $('#productQty').data('q');
        var $input = $('#productQty').val();

        if(max-1 < $input){
            $('#productQty').data('q', max);
            $('#productQty').val(max);
            $('#plusQty').addClass('cur-not-allowed');
            $('#plusQty').removeClass('rh-btn');

        } else if(max-1 > $input){
            $('#plusQty').removeClass('cur-not-allowed');
            $('#plusQty').addClass('rh-btn');
        }

        if(1 >= $input){
            $('#productQty').val(1);
            $('#minusQty').addClass('cur-not-allowed');
            $('#minusQty').removeClass('rh-btn');

        } else if(1 < $input){
            $('#minusQty').removeClass('cur-not-allowed');
            $('#minusQty').addClass('rh-btn');
        }


    });

    $('.plus').click(function () {
        var $input = $(this).parent().find('input');
        var max = $('#productQty').data('q');

        $('#minusQty').addClass('rh-btn');
        $('#minusQty').removeClass('cur-not-allowed');
        $('#minusQty').addClass('pointer');

        if($input.val() == max-1 ){
            $('#plusQty').addClass('cur-not-allowed');
            $('#plusQty').removeClass('rh-btn');
        }

        if($input.val() < max ){
            $input.val(parseInt($input.val()) + 1);
            $input.change();
            return false;
        }

    });

    $('.show-small-img:first-of-type').css({'border': 'solid 1px #ffc14f', 'padding': '1px'})
    $('.show-small-img:first-of-type').attr('alt', 'now').siblings().removeAttr('alt')
    $('.show-small-img').on('click mouseover', function () {
        var imgMD = $(this).attr('value')
        var imgLG = $(this).data('img')
        $('#zoom_mw').attr('src', imgMD)
        $('#zoom_mw').data('zoom-image', imgMD)
        $('.zoomWindow').css('background-image', 'url("'+imgLG+'")')
        $(this).attr('alt', 'now').siblings().removeAttr('alt')
        $(this).css({'border': 'solid 1px #ffc14f', 'padding': '1px'}).siblings().css({'border': 'none', 'padding': '0'})
        // if ($('#small-img-roll').children().length > 4) {
        //     if ($(this).index() >= 3 && $(this).index() < $('#small-img-roll').children().length - 1){
        //         $('#small-img-roll').css('left', -($(this).index() - 2) * 76 + 'px')
        //     } else if ($(this).index() == $('#small-img-roll').children().length - 1) {
        //         $('#small-img-roll').css('left', -($('#small-img-roll').children().length - 4) * 76 + 'px')
        //     } else {
        //         $('#small-img-roll').css('left', '0')
        //     }
        // }
    })

    $('#prev-img').on('click mouseover', function (){
        $('#show-img').attr('src', $(".show-small-img[alt='now']").prev().attr('src'))
        $('#big-img').attr('src', $(".show-small-img[alt='now']").prev().attr('src'))
        $(".show-small-img[alt='now']").prev().css({'border': 'solid 1px #ffc14f', 'padding': '1px'}).siblings().css({'border': 'none', 'padding': '0'})
        $(".show-small-img[alt='now']").prev().attr('alt', 'now').siblings().removeAttr('alt')
        if ($('#small-img-roll').children().length > 4) {
            if ($(".show-small-img[alt='now']").index() >= 3 && $(".show-small-img[alt='now']").index() < $('#small-img-roll').children().length - 1){
                $('#small-img-roll').css('left', -($(".show-small-img[alt='now']").index() - 2) * 76 + 'px')
            } else if ($(".show-small-img[alt='now']").index() == $('#small-img-roll').children().length - 1) {
                $('#small-img-roll').css('left', -($('#small-img-roll').children().length - 4) * 76 + 'px')
            } else {
                $('#small-img-roll').css('left', '0')
            }
        }

    })

    $('#next-img').on('click mouseover', function (){
        $('#show-img').attr('src', $(".show-small-img[alt='now']").next().attr('src'))
        $('#big-img').attr('src', $(".show-small-img[alt='now']").next().attr('src'))
        $(".show-small-img[alt='now']").next().css({'border': 'solid 1px #ffc14f', 'padding': '1px'}).siblings().css({'border': 'none', 'padding': '0'})
        $(".show-small-img[alt='now']").next().attr('alt', 'now').siblings().removeAttr('alt')
        if ($('#small-img-roll').children().length > 4) {
            if ($(".show-small-img[alt='now']").index() >= 3 && $(".show-small-img[alt='now']").index() < $('#small-img-roll').children().length - 1){
                $('#small-img-roll').css('left', -($(".show-small-img[alt='now']").index() - 2) * 76 + 'px')
            } else if ($(".show-small-img[alt='now']").index() == $('#small-img-roll').children().length - 1) {
                $('#small-img-roll').css('left', -($('#small-img-roll').children().length - 4) * 76 + 'px')
            } else {
                $('#small-img-roll').css('left', '0')
            }
        }

    })

    ;(function($){
        $.fn.jqSocialSharer = function(options){

            //settings
            var settings = $.extend({
                "popUpWidth" : 550,
                "popUpHeight": 450,
                "popUpTop"   : 58,
                "useCurrentLocation" : false
            }, options);

            return this.each(function(index, value){

                $(this).click(function(evt){
                    evt.preventDefault();

                    var social = $(this).data('info'),
                        width=settings.popUpWidth, /*Set the Pop-up Width*/
                        height=settings.popUpHeight, /*Set the Pop-up Height*/
                        sHeight=screen.height,
                        sWidth=screen.width,
                        left=Math.round((sWidth/2)-(width/2)),
                        top=settings.popUpTop,
                        url,
                        useCurrentLoc = settings.useCurrentLocation,
                        socialURL = (useCurrentLoc) ? window.location : encodeURIComponent(social.url),
                        socialText = social.text;

                    switch(social.type){
                        case 'facebook':
                            url = 'https://www.facebook.com/sharer.php?u='+ socialURL + '&t=' + socialText;
                            break;
                        case 'twitter':
                            url = 'https://twitter.com/share?url='+ socialURL + '&text=' + socialText;
                            break;
                        case 'gplus':
                            url = 'https://plus.google.com/share?url='+ socialURL + '&text=' + socialText;
                            break;
                    }

                    console.log(social);

                    /*Finally fire the Pop-up*/
                    window.open(url,'','left='+left+',top='+top+',width='+width+',height='+height+',personalbar=0,toolbar=0,scrollbars=1,resizable=1');
                });
            });
        };
    }(jQuery));

    $("#share dd a.shareSC").jqSocialSharer();

    $('#CloseModal').click(function () {
        $('#uploadimageModal').removeClass('bounceInUp');
        $('#uploadimageModal').removeClass('show');
        $('#uploadimageModal').css('display', 'none');
    });

    $("input[type='image']").click(function() {
        $("input[id='upload_image']").click();
        event.preventDefault();
    });


    // Job Hiring Application
    $("#jHForm").submit(function(e){
        e.preventDefault();
        var $this = $(this);

        $('#jHFormBtn').html('<i class="fa fa-spinner fa-spin"></i>&nbsp; SUBMITTING...')
        $('#jHFormBtn').removeAttr('onclick');

        $.ajax({
            url: $this.attr('action'),
            type: $this.attr('method'),
            data: new FormData(this),
            dataType: 'JSON',
            contentType: false,
            cache: false,
            processData: false,
            success: function (data) {

                $this[0].reset();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 10000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Application submitted! Please wait for our updates!'
                });

                $('#jHFormBtn').html('SUBMIT')
                $('#jHFormBtn').attr('onclick');

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);
                $('#jHFormBtn').html('SUBMIT')
                $('#jHFormBtn').attr('onclick');
                $('#upload_image').val('');
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 10000
                });

                Toast.fire({
                    type: 'error',
                    title: response.errors.file[0]
                });

            }
        });
    });


    // Message from contacts
    $("#msgForm").submit(function(e){
        e.preventDefault();
        var $this = $(this);

        $('#sendMsgBtn').html('<i class="fa fa-spinner fa-spin"></i>&nbsp; Sending...')

        $.ajax({
            url: $this.attr('action'),
            type: $this.attr('method'),
            data: new FormData(this),
            dataType: 'JSON',
            contentType: false,
            cache: false,
            processData: false,
            success: function (data) {

                $this[0].reset();
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 7000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Message successfully sent!'
                });

                $('#sendMsgBtn').html('<i class="fa fa-paper-plane"></i>&nbsp; Send&nbsp;&nbsp;')

            }
        });
    });

// Chat
// const chatMessages = document.querySelector('.chat-messages');
// const chatMessagesContent = document.querySelector('.chat-messages-content');
// const chatForm = document.querySelector('.chat-form');
//
// function init() {
//     const lastEl = chatMessagesContent.lastElementChild;
//     chatMessages.scrollTo(0, lastEl.offsetTop);
// }
//
// function onChatSubmit(evt) {
//     evt.preventDefault();
// }
//
// init();
//
// chatForm.addEventListener('submit', onChatSubmit);

});


