var url = $('meta[name="domain-name"]').attr('content')
var csrf_token = $('meta[name="csrf-token"]').attr('content')

function getLRoot(){
    $('#placesBreadCrumb').html('Select Address');
    $('#l-search-wrapper').html('<span class="fa fa-search l-form-control-feedback"></span><input type="text" id="lSearchBarProv" autocomplete="off" spellcheck="false" class="l-form-control l-center-search-input block-shadow-thick" placeholder="Select Address">');
    $('#placesHolder').html('<div class="p-5 mt-5 text-center" style="height: 230px"><i class="fa fa-spinner fa-spin"></i></div>');

    $.ajax({
        type:'get',
        url: url+'/fetchProvinces',
        success: function (data) {
            $('#placesHolder').html(data);
        },   
    });
}

function getLoader(){
    $('#placesHolder').html('<div class="p-5 mt-5 text-center" style="height: 230px"><i class="fa fa-spinner fa-spin"></i></div>');
}

$(document).on("click", "#lToogle", function() {
    var $this = $(this);
    var set = $this.data('set');
    getLRoot();

    if(set == 1){
        $('#myDropdown').css('display', 'block');
        $this.data('set', 0);

    } else {
        $('#myDropdown').css('display', 'none');
        $this.data('set', 1);

    }
});

$(document).on("click", ".setProvince", function() {
    var id = $(this).data('id');        
    getLoader();

    $.ajax({
        type:'POST',
        data: {
            _token: csrf_token,
            id: id,
        },
        url: url+'/fetchMunicipalities',
        success: function (data) {
            $('#placesBreadCrumb').html('<b class="text-theme pointer clearL1st">'+data.province_name+'</b>&nbsp; <i class="fa fa-angle-right"></i>&nbsp; Select Address');
            $('#l-search-wrapper').html('<span class="fa fa-search l-form-control-feedback"></span><input type="text" id="lSearchBarMun" autocomplete="off" data-code="'+data.province_code+'" spellcheck="false" class="l-form-control l-center-search-input block-shadow-thick" placeholder="Select Address">');
            $('#placesHolder').html(data.html);

        }
    });
});

$(document).on("click", ".setMun", function() {
    var id = $(this).data('id');        
    getLoader();

    $.ajax({
        type:'POST',
        data: {
            _token: csrf_token,
            id: id,
        },
        url: url+'/fetchBarangays',
        success: function (data) {
            $('#placesBreadCrumb').html('<b class="text-theme pointer clearL1st" style="height: 50px;">'+data.province_name+'</b>&nbsp; <i class="fa fa-angle-right"></i>&nbsp;<b class="text-theme pointer clearL2nd" data-code="'+data.province_code+'">&nbsp;'+data.mun_name+'</b>&nbsp;&nbsp;<i class="fa fa-angle-right"></i>&nbsp; Select Address');
            $('#l-search-wrapper').html('<span class="fa fa-search l-form-control-feedback"></span><input type="text" id="lSearchBarBrgy" autocomplete="off" data-code="'+data.mun_code+'" spellcheck="false" class="l-form-control l-center-search-input block-shadow-thick" placeholder="Select Address">');
            $('#placesHolder').html(data.html);

        }
    });
});

$(document).on("click", ".setBrgy", function() {
    var id = $(this).data('id');        
    getLoader();

    $.ajax({
        type:'POST',
        data: {
            _token: csrf_token,
            id: id,
        },
        url: url+'/setAddress',
        success: function (data) {
            $('.settedAddress').html(data.address);
            $('.settedShFee').html(data.shipping_fee);
            $('#myDropdown').css('display', 'none');
            getLRoot();

        }
    });

});


$(document).on("click", ".clearL1st", function() {
    getLRoot();
});

$(document).on("click", ".clearL2nd", function() {
    var provCode = $(this).data('code');
    getLoader();

    $.ajax({
        type:'POST',
        data: {
            _token: csrf_token,
            keyword: '',
            providerCode1: provCode,
        },
        url: url+'/fetchMunicipalitiesSearch',
        success:function(data){
            $('#placesBreadCrumb').html('<b class="text-theme pointer clearL1st">'+data.province_name+'</b>&nbsp; <i class="fa fa-angle-right"></i>&nbsp; Select Address');
            $('#l-search-wrapper').html('<span class="fa fa-search l-form-control-feedback"></span><input type="text" id="lSearchBarMun" autocomplete="off" data-code="'+data.province_code+'" spellcheck="false" class="l-form-control l-center-search-input block-shadow-thick" placeholder="Select Address">');
            $('#placesHolder').html(data.html);
        }

    });
});

$(document).on('keyup', '#lSearchBarProv', function(e){
    var keyword = $(this).val();
    $('#placesHolder').html('<div class="p-5 mt-5 text-center" style="height: 230px"><i class="fa fa-spinner fa-spin"></i></div>');

    if(keyword != '') {
        $.ajax({
            type:'POST',
            data: {
                _token: csrf_token,
                keyword: keyword,
            },
            url: url+'/fetchProvincesSearch',
            success:function(data){
                $('#placesHolder').html(data);

            }
            
        });

    } else {
        getLRoot();
    }
});

$(document).on('keyup', '#lSearchBarMun', function(e){
    var keyword = $(this).val();
    var providerCode1 = $(this).data('code');
    getLoader();

    $.ajax({
        type:'POST',
        data: {
            _token: csrf_token,
            keyword: keyword,
            providerCode1: providerCode1,
        },
        url: url+'/fetchMunicipalitiesSearch',
        success:function(data){
            $('#placesBreadCrumb').html('<b class="text-theme pointer clearL1st">'+data.province_name+'</b>&nbsp; <i class="fa fa-angle-right"></i>&nbsp; Select Address');
            $('#placesHolder').html(data.html);
        }

    });
});

$(document).on('keyup', '#lSearchBarBrgy', function(e){
    var keyword = $(this).val();
    var munCode1 = $(this).data('code');
    getLoader();

    if(keyword != '') {
        $.ajax({
            type:'POST',
            data: {
                _token: csrf_token,
                keyword: keyword,
                munCode1: munCode1

            },
            url: url+'/fetchBarangaysSearch',
            success:function(data){
                $('#placesHolder').html(data.html);
            }
            
        });
    } 
});