$(document).ready(function () {
    function progressBarAt70Percent() {
        var options = {
            classname: 'my-class',
            id: 'my-id',
            target: document.getElementById('myDivId')
        };
        
        var nanobar = new Nanobar(options);
        
        //move bar
        nanobar.go(30); // size bar 30%
        nanobar.go(76); // size bar 76%
        
        nanobar.go(0); // size bar 76%

        // size bar 100% and and finish
        // nanobar.go(100);
    }

    function progressBarAtSuccess() {
        var options = {
            classname: 'my-class',
            id: 'my-id',
            target: document.getElementById('myDivId')
        };
        
        var nanobar2 = new Nanobar(options);

        // size bar 100% and and finish
        nanobar2.go(100);
    }

    function pushState(finalURL) {
        history.pushState({}, "null", finalURL + '')
    }

    function toTop() {
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }

  function makeSkeletonCard()
  {
    var output = '';
    for(var count = 0; count < 5; count++)
    {
        var output = '';
        for(var count = 0; count < 1; count++)
        {
          output += '<div class="ph-item p-0 card-body product-card-body-inca" style="height: 390px!important;width: 237px!important">';
            output += '<div class="ph-col-12">';
            output += '<div class="ph-picture" style="height: 180px!important"></div>';
          output += '</div>';
          output += '<div>';
          output += '<div class="ph-row mt-3">';
          output += '<div class="ph-col-12 mt-3"></div>';
        output += '<div class="ph-col-12"></div>';
        output += '<div class="ph-col-2 mt-2"></div>';
        output += '<div class="ph-col-2  mt-2"></div>';
        output += '<div class="ph-col-12 empty"></div>';
        output += '<div class="ph-col-12 empty"></div>';
        output += '<div class="ph-col-6 mt-2"></div>';
        output += '<div class="ph-col-6 mt-2"></div>';
            
          output += '</div>';
          output += '</div>';
          output += '</div>';

        }
        return output;
        }
   }
    
    function showSkeleton() {
        $('.productsCardSkeleton').html(makeSkeletonCard());
        $('.productsCardSkeleton').removeClass('d-none');
        
    }

    function hideSkeleton() {
        $('.productsCardSkeleton').addClass('d-none');
        
    }

    showSkeleton();

    var url, finalURL, provider;
        
    url = $('meta[name="domain-name"]').attr('content')
    provider = $('#provider').data('provider');
    urlWithProvider = url + '/' + provider + '/';

    function dataRefresher() {
        var sort, style, keyword;

        return [
            sort = $('#sort').data('sort'),
            style = $('#style').data('style'),
            keyword = $('#keyword').data('keyword'),
        ]
    }

    setTimeout(function () {
        var data = dataRefresher();
        progressBarAt70Percent();
   
        finalURL = urlWithProvider + data[0] + '/' + data[1] + '/' + data[2];
            
        $.ajax({
            type: 'get',
            url: finalURL,
            success: function (data) {
                progressBarAtSuccess();
                $('#productsCard').html(data.card)
                hideSkeleton()

            }, error: function (jqXHR) {
                hideSkeleton()
                progressBarAtSuccess();
            
            }
        })

    }, 3000);
    
    
    $('.category-list-selected').on('click', function (e) {
        e.preventDefault();
        progressBarAt70Percent();
        showSkeleton();

        var $this = $(this);

        var data = dataRefresher();

        var this_keyword = $(this).data('keyword')

        if (keyword != this_keyword) {
            this_keyword = this_keyword;

        } else {
            this_keyword = keyword;
        }
       
        finalURL = urlWithProvider + data[0] + '/' + data[1] + '/' + this_keyword;
        
        pushState(finalURL);
        
        $.ajax({
            type: 'get',
            url: finalURL,
            success: function (data) {
                $this.children('a').addClass('text-danger')
                $this.siblings().children('a').removeClass('text-danger')
                progressBarAtSuccess();
                hideSkeleton()

                $('#productsCard').html(data.card)
                $('.pageTitle').html(data.pageTitle)
                $('#keyword').data('keyword', data.keyword)
                $('#sort').data('sort', data.sort)

                toTop();
            
            }, error: function (jqXHR) {
                $('.productsCardSkeleton').addClass('d-none');
                progressBarAtSuccess();
                hideSkeleton()
            }
        })
    })
        
    $('.category-sort-selected').on('click', function (e) {
        e.preventDefault();
        progressBarAt70Percent();
        showSkeleton();

        $(this).addClass('active')
        $(this).siblings('a').removeClass('active')
        
        var data = dataRefresher();

        sort = $(this).data('sort')
        finalURL = urlWithProvider + sort + '/' + data[1] + '/' + data[2];

        pushState(finalURL);
        if (sort == 'asc') {
            sortState = 'Price low to high';

        } else if (sort == 'desc') {
            sortState = 'Price high to low';

        } else {
            sortState = 'Popularity';
        }
        
        $.ajax({
            type:'get',
            url: finalURL,
            success: function (data) {
                progressBarAtSuccess();
                $('#productsCard').html(data.card)
                $('#sortViewState').html(sortState)
                $('#sort').data('sort', data.sort)
                $('.rotateSortIcon').removeClass('down')
                $('.rotateSortIcon').removeClass('fa-chevron-up')
                $('.rotateSortIcon').addClass('fa-chevron-down')
                hideSkeleton()
                
            },  error: function (jqXHR) {
                hideSkeleton()

            }
        })
    })

    $('.styleGrid').on('click', function (e) {
        e.preventDefault();
        progressBarAt70Percent();
        $(this).children('i').removeClass('opacity')
        $(this).siblings().children('i').addClass('opacity')

        var sortState;

        var data = dataRefresher();

        style = $(this).data('style')

        finalURL = urlWithProvider + data[0] + '/' + style + '/' + data[2];
        pushState(finalURL);

        $.ajax({
            type:'get',
            url: finalURL,
            success: function (data) {
                progressBarAtSuccess();
                $('#productsCard').html(data.card)
                $('#sortViewState').html(sortState)
                $('#style').data('style', style)

            },  error: function (jqXHR) {


            }
        })
    })

});