$(document).ready(function (){
    $(document).on('click mouseover', '.variation-selector', function(){
        var url =  $('#getVariation').data('url')
        var token = $('meta[name="csrf-token"]').attr('content')

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': token
            }
        });

        var sku = $(this).data('sku');
            $.ajax({
                type:'POST',
                data: {
                    _token: token,
                    sku: sku,
                },
                url: url,
                success: function (data) {
                    $('#varName').html(data.varName);
                    $('.view-price').html(data.varPrice);
                    $('#productQty').data('q', data.varQty);
                    $('#productQtyDisplay').html(data.productQtyDisplay);

                    if(data.varQty == 0){
                        $('#productQty').attr('val', 1);
                    }
                    
                }
            });
    })
})