    
      $("#add2Cart").on("click", function() {
        var id = $(this).data('id');
        var qty = $('#productQty').val();

        $.ajax({
            type:'POST',
            data: {
                _token: _CSRFToken,
                id: id,
                qty: qty,
            },
            url: rootURL+'/add-to-cart',
            success: function (data) {
                if(data.error == true){
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 790
                    });
    
                    Toast.fire({
                        type: 'error',
                        title: data.message
                    });
    
                } else {
                    $('#cartContent').html(data.html);
                    $('.cart-badge-wrapper').removeClass('d-none');
                    $('.cart-badge').html(data.total_qty);
    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 2000
                    });
    
                    Toast.fire({
                        type: 'success',
                        title: 'Item successfully added to cart!'
                    });

                }

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 790
                });

                Toast.fire({
                    type: 'error',
                    title: 'Failed to add item to cart!'
                });

            }
        });

    });

    $(document).on("click", ".mn-cart-minus", function() {
        var item_id = $(this).siblings('.id').data('id');    
        var qty = $(this).siblings('.qty').val();     

        function qtyMinus(){
            $.ajax({
                type:'POST',
                data: {
                    _token: _CSRFToken,
                    item_id: item_id,
                    qty: qty,
                },
                url: rootURL+'/cart/updateMinus',
                success: function (data) {
                    if(data.error == true){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 790
                        });
        
                        Toast.fire({
                            type: 'error',
                            title: data.message
                        });
        
                    } else {
                        $('#mainCart').html(data.html);
                        $('.cart-badge-wrapper').removeClass('d-none');
                        $('.cart-badge').html(data.total_qty);
                
                    }
                }
    
            });
        }

        if(qty == 1){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn bg-theme text-white rh-btn pl-4 pt-2 pb-2 pr-5 ml-3',
                    cancelButton: 'btn btn-white rh-btn pl-4  pt-2 pb-2 pr-5',
                    popup: 'animated slide-up'
                },
                animation: false,
                buttonsStyling: false
            })
                swalWithBootstrapButtons.fire({
                    title: 'Do you want to remove this item?',
                    text: '',
                    type: '',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    reverseButtons: true

                }).then((e) => {
                    if ((e).value) {
                        if (e.value === true) {
                            qtyMinus();
    
                    }
                } 
            });
       
        } else { 
            qtyMinus();
        }
     
    });

    $(document).on('keyup', '.qty', function(){
        var keyword = $(this).val();
        var item_id = $(this).siblings('.id').data('id');    
         
        if((keyword != '')) {
            $.ajax({
                type:'POST',
                data: {
                    _token: _CSRFToken,
                    qty: keyword,
                    item_id: item_id,
                },
                url: rootURL+'/cart/updateInput',
                success:function(data){
                  
                    if(data.error == true){
                        $('#mainCart').html(data.html);

                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 790
                        });
        
                        Toast.fire({
                            type: 'error',
                            title: data.message
                        });
        

                    } else {
                        $('#mainCart').html(data.html);

                   
                    }

                    
                }
            });

        } 

    });

    $(document).on("click", ".cr-select", function() {
        var stat = $(this).data('stat');    
        var item_id = $(this).siblings('.checkmark').data('id');    
        $.ajax({
            type:'POST',
            data: {
                _token: _CSRFToken,
                item_id: item_id,
                stat: stat,
            },
            url: rootURL+'/cart/select',
            success: function (data) {
                $('#mainCart').html(data.html);

            }
        });
    });

    $(document).on("click", "#selectAll", function() {
        var stat = $(this).data('stat');    

        console.log(stat);

        $.ajax({
            type:'POST',
            data: {
                _token: _CSRFToken,
                stat: stat,
            },
            url: rootURL+'/cart/selectAll',
            success: function (data) {
                $('#mainCart').html(data.html);

            }
        });

    });

    $(document).on("click", ".mn-cart-plus", function() {
        var item_id = $(this).siblings('.id').data('id');    
        var qty = $(this).siblings('.qty').val();        

        $.ajax({
            type:'POST',
            data: {
                _token: _CSRFToken,
                item_id: item_id,
                qty: qty,
            },
            url: rootURL+'/cart/updatePlus',
            success: function (data) {
                if(data.error == true){
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 790
                    });
    
                    Toast.fire({
                        type: 'error',
                        title: data.message
                    });
    
                } else {
                    $('#mainCart').html(data.html);
                    $('.cart-badge-wrapper').removeClass('d-none');
                    $('.cart-badge').html(data.total_qty);
               
                }
            }
        });
    });

    // cart-direct
    $(document).on("click", ".cart-direct", function(){
        var finalURL = rootURL+'/cart';
        history.pushState({}, "null", ''+finalURL+'');
        location.reload();
    });

    $("#buyNow").on("click", function() {
        var id = $(this).data('id');
        var qty = $('#productQty').val();

        console.log('clicked!');
        $.ajax({
            type:'POST',
            data: {
                _token: _CSRFToken,
                id: id,
                qty: qty,
            },
            url: rootURL+'/add-to-cart',
            success: function (data) {
                if(data.error == true){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 790
                        });
        
                        Toast.fire({
                            type: 'error',
                            title: data.message
                        });

                    } else {
                        $('#cartContent').html(data.html);
                        $('.cart-badge-wrapper').removeClass('d-none');
                        $('.cart-badge').html(data.total_qty);
        
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'center',
                            showConfirmButton: false,
                            timer: 790
                        });
        
                        Toast.fire({
                            type: 'success',
                            title: 'Item successfully added to cart!'
                        });
    
                        setTimeout(function(){ window.top.location=''+rootURL+'/cart' } , 790);   

                    }

                },
                error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    type: 'error',
                    title: 'Failed to add item to cart!'
                });

            }
        });

    });

    $(document).on("click", ".cart-check__out", function(e){
        var $this = $(this);
        $this.html('<i class="fa fa-spinner fa-spin"></i>&nbsp; Checking Out');
        
        $.ajax({
            type:'get',
            url: rootURL+'/cart/pre_check_out',
            success: function (data) {

                if(data.success){
                    setTimeout(function(){ window.top.location=''+rootURL+'/cart/checkout/'+_CSRFToken } , 1);   

                } else {
                    $this.html('&nbsp; Check Out');
    
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 3000
                    });
                    Toast.fire({
                        type: 'warning',
                        title: 'Please select an item(s) to procced!'
                    });

                }
            },
            error: function (data) {
              

            }
        });

    });

    $(document).on("click", ".cart-place__order", function(e){
        var $this = $(this);
        $this.html('<i class="fa fa-spinner fa-spin"></i>&nbsp; Place Order');
        var address = $('#address').html();
        var message = $('.message').val();

        $.ajax({
            type:'POST',
            data: {
                shipment_address: address,
                message: message
            },
            url: rootURL+'/cart/place_order',
            success: function (data) {
                $this.html('&nbsp; Place Order');
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 6000
                });

                Toast.fire({
                    type: 'success',
                    title: 'Order Successfully Placed!'
                });

                setTimeout(function(){ window.top.location=''+rootURL+'/user/orders/pending' } , 900);   
            
            },
            error: function() {
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000
                });
                Toast.fire({
                    type: 'warning',
                    title: 'Internet Connection Error!'
                });

            }
        });
        
    });

    $(document).on("click", ".dropdown-cart-product-delete", function(e){
        e.preventDefault();
        var id = $(this).data('id');
        
        $.ajax({
            type:'POST',
            data: {
                _token: _CSRFToken,
                id: id,
            },
            url: rootURL+'/remove-from-cart',
            success: function (data) {
                $('#cartContent').html(data.returnMainCartDropdown);
                $('#mainCart').html(data.returnMainCart);
                $('.cart-badge').html(data.total_qty);

                if(data.total_qty == 0){
                    $('.cart-badge-wrapper').addClass('d-none');

                }

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 3000
                });
                Toast.fire({
                    type: 'success',
                    title: 'Item successfully removed from cart!'
                });

            },
            error: function (jqXHR) {
                var response = $.parseJSON(jqXHR.responseText);

            }
        });
        
    });
    
    $(document).on("click", ".cart-product-delete", function(e){
        e.preventDefault();
        var id = $(this).data('id');
        var item = $(this).children('.fa-trash-alt').data('item');
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn bg-theme text-white rh-btn pl-4 pt-2 pb-2 pr-5 ml-3',
                cancelButton: 'btn btn-white rh-btn pl-4  pt-2 pb-2 pr-5',
                popup: 'animated fadeInUp'
            },
            animation: false,
            buttonsStyling: false
        })
            swalWithBootstrapButtons.fire({
                title: 'Do you want to remove this item?',
                text: item,
                type: '',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: true

            }).then((e) => {

                if ((e).value) {
                    if (e.value === true) {

                    $.ajax({
                        type:'POST',
                        data: {
                            _token: _CSRFToken,
                            id: id,
                        },
                        url: rootURL+'/remove-from-cart',
                        success: function (data) {
                            $('#cartContent').html(data.returnMainCartDropdown);
                            $('#mainCart').html(data.returnMainCart);
                            $('.cart-badge').html(data.total_qty);
            
                            if(data.total_qty == 0){
                                $('.cart-badge-wrapper').addClass('d-none');
            
                            }
                         
            
                        },
                        error: function (jqXHR) {
                            var response = $.parseJSON(jqXHR.responseText);
            
                        }
                    });

                }

            } 

        });
    });
