    // Animation
    $(window).scroll(function() {
        $(".slideAnimTop").each(function(){
            var pos = $(this).offset().top;
            var winTop = $(window).scrollTop();
            if (pos < winTop + 600) {
                $(this).addClass("slideTop");
            }
        });
    });

    $(window).scroll(function() {
        $(".slideAnimDown").each(function(){
            var pos = $(this).offset().top;
            var winTop = $(window).scrollTop();
            if (pos < winTop + 600) {
                $(this).addClass("slideDown");
            }
        });
    });

    $(window).scroll(function() {
        $(".slideAnimLeft").each(function(){
            var pos = $(this).offset().top;
            var winTop = $(window).scrollTop();
            if (pos < winTop + 600) {
                $(this).addClass("slideLeft");
            }
        });
    });

    $(window).scroll(function() {
        $(".slideAnimRight").each(function(){
            var pos = $(this).offset().top;
            var winTop = $(window).scrollTop();
            if (pos < winTop + 300) {
                $(this).addClass("slideRight");
            }
        });
    });